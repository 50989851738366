import { Atom, SHARED_UTILS } from ":mods";
import { SignupPopupComponentViewProps } from "../model";
import { Ctrl, Mod } from ":mods";

// TODO: move this to account navs and add it microsite controller addons
export function SignupPopup({ onClosePopup, ...props }: SignupPopupComponentViewProps) {
  const closePopup = ({ currentTarget }) => {
    if (currentTarget.id == "main-layout") onClosePopup();
  };
  const signup_link = SHARED_UTILS.populateRouteHref({
    base: Ctrl.Routes.CONST.BASE_ROUTES.auth,
    path: Mod.Account.CONST.ROUTES.auth.signup,
  });
  return (
    <div
      id="main-layout"
      class={`w-screen h-screen flex flex-col items-center justify-center absolute z-20 ${props.class}`}
      onClick={closePopup}
    >
      <section class="flex flex-col items-center justify-center text-center gap-20px w-558px absolute p-80px bg-white text#p font-semibold rounded-20px z-24">
        <header class="text-39px">
          <h1>Welcome to Museum Futures Platform</h1>
        </header>
        <p class="text-14px">What do you want to sign up as?</p>
        <div class="flex gap-20px text-16px mt-20px">
          <Atom.Buttons.Button
            cls="bg-white"
            theme={"light"}
            outlined={true}
            link={`${signup_link}?type=student`}
            text={"Student"}
          />
          <Atom.Buttons.Button
            cls="bg-white"
            theme={"light"}
            outlined={true}
            link={`${signup_link}?type=tutor`}
            text={"Tutor / Expert"}
          />
        </div>
      </section>
    </div>
  );
}
